.App {
  /* text-align: center; */
  background-color: #ffffff;
  border-color: #fff;
  color: rgba(0, 0, 0, .87);
  padding-top: 48px!important;
  line-height: 1.5;
  font-family: Roboto, Noto Sans S Chinese, PingFangSC, Microsoft YaHei, sans-serif;
}

.real-header {
  font-weight: bold;
  color: green;
  font-size: 24px;
  /* width: 100%; */
  display: inline-block;
  padding: 0;
  margin-bottom: 16px!important;
  padding: 16px;
}

.real-body {
  background-color: #fff;
  color: rgba(0, 0, 0, .87);
  list-style-type: none;
  padding: 8px 0 8px;
  margin: 0;
  display: block;
  box-sizing: inherit;
}

.real-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-color: rgba(0, 0, 0, .12);
  border-width: thin 0 0 0;
  transition: inherit;
  color: rgba(0,0,0,.87);
  overflow: visible;
  margin: 0;
}

.listitem {
  margin: 0;
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  /* transition: background .3s cubic-bezier(.25,.8,.5,1); */
}

.listicon {
  color: rgba(0, 0, 0, .54);
  font-size: 24px;
  justify-content: center;
  line-height: 1;
  height: 24px;
}

.listdesc {
  margin-left: 8px!important;
  margin-right: 8px!important;
}

.listaction {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-width: 56px;
}

.listcontent {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.timewrapper {
  width: 180px;
  line-height: 24px;
  text-align: left;
  color: #00c4a4;
  caret-color: #00c4a4 !important;
  font-size: 1.17em;
  font-weight: bold;
  overflow: hidden;
}

.timebox {
  width: 80000%;
  /* transform: translateX(10px); */
}
.timebox div {
  float: left;
}

.marquee {
  /* width: 183px; */
  margin: 0 10px 0 0;
}

@media (prefers-reduced-motion: no-preference) {
  .timebox {
    animation: timebox-move infinite 6s linear;
  }
}

@keyframes timebox-move {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-188px);
    /* transform: translateX( -calc ) */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
